<template>
  <div class="">Loading....</div>
</template>

<script>
export default {
  components: {},
  created() {
    const self = this;
      self.actions.logout();
      self.$router.push({ name: "Home" });
  },
};
</script>
